<template>
  <div>
    <Loader :loading="loading" />
    <v-row>
      <v-col cols="12" md="8" sm="12">
        <v-form ref="form" onsubmit="return false;">
          <v-row>
            <v-col cols="12" md="6" sm="6" class="pb-0">
              <v-text-field
                v-model="order.order_num"
                label="Order #"
                dense
                outlined
                :error-messages="errorNumber"
                :rules="[isRequired]"
              />
              <v-textarea
                v-model="order.instructions"
                label="Instrucciones"
                dense
                outlined
                rows="2"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" class="pb-0">
              <SelectWarehouse
                v-model="warehouseDestiny"
                label="Almacen destino"
                outlined
                :rules=[isRequiredObj]
              />
             
              <!-- <SelectContacts
                type="customer"
                model="contact"
                v-model="entityDestiny"
                label="Cliente"
                outlined
              /> -->
            </v-col>
          </v-row>
        </v-form>
        <Items
          :order-items="items"
          @onRemoveItem="onRemoveItem"
        />
        <div>
          <h5>Total: <b class="success--text">Bs. {{ total }}</b></h5>
        </div>
      </v-col>
      <v-col cols="12" md="4" sm="12">
        <div class="d-flex justify-end mb-1">
          <v-btn
            color="primary"
            small
            tile
            elevation="0"
            @click="storeOrder"
            :disabled="!validateItems"
          >
            Finalizar
          </v-btn>
        </div>
        <OrderInventories
          ref="inventories"
          :warehouse="user.warehouse"
          @onSendSku="onGetSku"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
//services
import OrderServices from '@/store/services/warehouse/OrderServices';
import StatusServices from '@/store/services/general/StatusServices';
// components
import OrderInventories from '@/views/warehouse/order/OrderInventories';
import Items from './OrderItems';
import SelectWarehouse from '@/components/commons/SelectWarehouse';
// import SelectContacts from '@/components/commons/SelectContacts';
// helpers
import { mapGetters } from 'vuex';
import { objectEmpty } from '@/constants/helpers';
export default {
  components: {
    OrderInventories,
    Items,
    SelectWarehouse,
    // SelectContacts
  },
  data() {
    return {
      errorNumber: '',
      loading: false,

      items: [],
      warehouses: [],
      statuses: [],

      order: {
        user_id: '',
        origin_warehouse_id: null,
        destiny_warehouse_id: null,
        buy_contact_id: null,
        bill_contact_id: null,
        order_num: this.getOrderNum(),
        instructions: '',
        total: 0,
        delivery_date: new Date(),
        type: '',
        status_id: '',
      },

      warehouseDestiny: {},

      //rules
      isRequired: value => !!value || 'Campo Requerido',
      isRequiredObj: value => !objectEmpty(value) || 'Campo requerido',
    };
  },
  created() {
    this.getStatuses();
  },
  methods: {
    async getStatuses() {
      try {
        const response = await StatusServices.index(this.user.archon.id);
        this.statuses = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getStatus(name) {
      const index = this.statuses.findIndex(status => status.name === name);
      return this.statuses[index];
    },
    getOrderNum() {
      const n = Math.round(Math.random()*999999 + 9999999);
      return `O-${n}`;
    },
    onGetSku(data) {
      console.log(data);
      const index = this.items.findIndex(item => item.sku_id === data.sku.id);
      if (index === -1) {
        // sku_id: data.sku.id,
        // sku: data.sku,
        // qty_available: data.qty_available,
        // discount: 0,
        // quantity: 1,

        // sold_price: priceable?.value ?? 0,
        // min_sold_price: priceable?.value ?? 0,
        // package_quantity: priceable?.units ?? 1,
        // package_name: priceable?.package?.name ?? 'Pieza',
        const newItem = {
          sku_id: data.sku.id,
          sku: data.sku,
          qty_available: data.qty_available,
          discount: 0,
          quantity: 1,

          sold_price: 0,
          min_sold_price: 0,
          package_quantity: 1,
          package_name: 'Pieza',

          // product: data.sku.product.name,
          // min_sold_price: priceable?.value ?? 0,
          // package_quantity: priceable?.units ?? 1,
          // package_name: priceable?.package?.name ?? 'Pieza',
        };
        this.items.unshift(newItem);
      } else {
        const oldQuantity = parseInt(this.items[index].quantity);
        this.items[index].quantity = oldQuantity + parseInt(data.quantity);
      }
    },
    onRemoveItem(data) {
      const index = this.items.findIndex(item => item.sku_id === data.sku.sku_id);
      this.items.splice(index, 1);
    },
    async storeOrder() {
      if (this.$refs.form.validate()) {
        if (this.items.length > 0) {
          try {
            this.errorNumber = '';
            let status = {};
            console.log(this.user);
            this.order.user_id = this.user.id;
            this.order.origin_warehouse_id = this.user.warehouse.id;
            this.order.destiny_warehouse_id = this.warehouseDestiny.id;
            status = this.getStatus('FAST_SHIPPING');
            this.order.type = 'DISPATCH';
            this.order.status_id = status.id;
            this.order.total = this.total;
            this.order['items'] = this.items.map(item => ({
              sku_id: item.sku_id,
              quantity: item.quantity,
              sold_price: 12,
            }));
            this.loading = true;
            await OrderServices.store(this.user.archon.id, this.order);
            this.$router.push({
              name: 'movements',
            });
            this.$notify({
              group: 'petition',
              title: 'Orden',
              type: 'success',
              text: `Orden ${this.order.order_num} guardado exitosamente`,
            });
            this.loading = false;
          } catch (error) {
            console.log(error);
            this.$notify({
              group: 'petition',
              type: 'error',
              title: 'Orden',
              text: `Orden ${this.order.order_num} no se pudo guardar`,
            });
            this.loading = false;
            if (error.response.status === 422) {
              const errorNumber = error.response.data.errors?.order_num[0] || null;
              if (errorNumber) {
                this.errorNumber = errorNumber;
              }
            }
          }
        } else this.$notify({
          group: 'petition',
          type: 'warning',
          title: 'Skus',
          text: `La orden no tiene skus agregados`,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    validateItems() {
      return this.items.filter(sku => parseInt(sku.quantity) > parseInt(sku.qty_available)).length === 0;
    },
    total() {
      let total = 0;
      this.items.forEach(item => {
        total += (item.sold_price * item.quantity);
      });
      return total.toFixed(2);
    },
  },
}
</script>